.modal {
  position: fixed;
  top: 35%;
  left: 35%;
  width: 730px;
  height: 405px;
  background-color: #fff;
  border: 1px solid #3dbf75;
  border-radius: 15px;
}

.modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal-image-text-content {
  margin-top: 10%;
  text-align: center;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}
