.siderbar-title{
    font-size: 13px;
    font-weight: 700;
    color: #fff;
    padding-left: 20px;
}

.content{
    background-color: #25ae60;
    height: 100vh;
    
}

.sidebar-navitem{
    background-color: #42425c;
    margin: 5px 0;
    padding: 5px 0;
}

.sidebar-image-text-container{
    display: flex;
    flex-direction: row;
    align-items: center;
}


.sidebar-link{
    margin-left: 4px;
    color: #fff;
}