.section {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section-white {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-section {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-section{
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
}
