.pass-reset-background {
  background: url(../../images/login_banner.png);
  background-repeat: no-repeat;
  width: 793px;
  height: 750px;
}
.banner-pass-reset-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.pass-reset-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.pass-reset-container-content {
  width: 450px;
  margin: auto;
  background: #ffffff;
  padding: 40px 55px 45px 55px;
}

.pass-reset-title {
  margin-bottom: 20px;
  text-align: center;
  color: #cccdd0;
  font-size: 14px;
}

.pass-reset-email {
  margin-bottom: 15px;
}

.pass-reset-input {
  border: none;
  border-bottom: 1px solid #e9e9f0;
}

.pass-reset-input::placeholder {
  font-weight: 700;
}

.pass-reset-button-container {
  margin-top: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.pass-reset-bttn {
  border: 1px solid #3dbf75;
  border-radius: 8px;
  width: 180px;
  height: 35px;
  background-color: #3dbf75;
  text-align: center;
}
