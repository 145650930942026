.about-info {
  margin-top: 30px;
  width: 50%;
}

.about-content {
  margin-top: 30px;
}

.about-content-text {
  width: 70%;
}

.info {
  width: 400px;
  color: #fff;
  font-weight: 500;
  margin-top: 50px;
}

.banner {
  background-image: url(../../images/camada-superior.png),
    url(../../images/banner-principal.png);
  background-repeat: no-repeat, no-repeat;
}

.banner-principal-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.banner-principal-title {
  color: #fff;
  margin-bottom: 15px;
  font-size: 70px;
  font-weight: 400;
}

.banner-principal-img {
  display: block;
  margin-bottom: 16px;
}

.capa-content {
  background-color: #fff;
  margin-bottom: 30px;
}

.capa-logo-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.capa-separator-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.capa-separator-container-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.capa-separator {
  height: 90px;
  border: 1px solid#48a057;
  border-radius: 1px;
}

.capa-text-container {
  display: flex;
  align-items: center;
}

.capa-text-container-text {
  font-size: 14px;
  font-weight: 400;
}

.capa-benefits-container {
  margin-top: 40px;
  text-align: center;
}

.capa-benefits-content {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.capa-benefits-content-title {
  font-weight: 700;
  font-size: 21px;
}

.capa-benefits-content-info {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
}

.content-a {
  background-image: url(../../images/ruido-2.png), url(../../images/ruido-1.png),
    url(../../images/lady-card.png);
  background-repeat: no-repeat, no-repeat, no-repeat;
  padding-top: 30px;
}

.content-b {
  background-color: #fff;
  margin-bottom: 20px;
}

.content-a-container {
  display: block;
}

.content-c {
  background-image: url(../../images/ruido-3.png), url(../../images/cards.png);
  background-repeat: no-repeat, no-repeat;
  background-position-x: center, -3px;
  background-position-y: center, -410px;
}

.content-c-container {
  height: 460px;
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content-c-link {
  color: #fff;
  text-decoration: none;
  border: 1px solid #85af4b;
  background-color: #85af4b;
  width: fit-content;
  padding: 6px 25px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: 400;
}

.content-c-link:hover {
  color: #000;
}

.content-d {
  background-image: url(../../images/pai_filha.png);
  background-repeat: no-repeat;
  min-height: 80vh;
  margin-top: 90px;
}

.details {
  color: #008257;
  font-weight: 700;
}

.line {
  height: 1px;
  width: 50px;
  background-color: #008257;
}

.features-container {
  margin-top: 30px;
}

.features-content {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.features-title {
  font-weight: 700;
  font-size: 20px;
}

.features-details {
  margin-top: 50px;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
}

.separador {
  margin-top: 60px;
  margin-bottom: 60px;
  border: 1px solid#D3D3D3;
}

.parteners-container {
  padding-bottom: 60px;
}

.partners-title {
  text-align: center;
  font-weight: 400;
  font-size: 20px;
}

.parteners-img {
  height: 100px;
}

.content-b-second-subtitle {
  margin-top: 10px;
  color: #000;
  font-size: 36px;
  text-align: center;
  font-weight: 400;
}

.why-to-use {
  margin-top: 40px;
}

.why-to-use-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.why-to-use-text {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.aquire-plan-title {
  font-size: 40px;
  font-weight: 300;
}

.aquire-plan-text {
  font-size: 14px;
  font-weight: 400;
}

.ler-mais-links {
  border: 1px solid #fff;
  border-radius: 15px;
  color: #fff;
  padding: 8px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
}

.ler-mais-links:hover {
  color: #000;
}

.teste {
  background-color: aqua;
  min-height: 140px;
}
