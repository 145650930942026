.login-background{
  background: url(../../images/login_banner.png);
  background-repeat: no-repeat;
  width: 793px;
  height: 750px;
}
.banner-login-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  
}
.login-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.login-container-content {
  width: 450px;
  margin: auto;
  background: #ffffff;
  padding: 40px 55px 45px 55px;
  ;
}

.login-title {
  margin-bottom: 20px;
  text-align: center;
  color: #cccdd0;
  font-size: 14px;
}

.login-input{
  border: none;
  border-bottom: 1px solid #e9e9f0;
}

.login-input::placeholder{
  font-weight: 700;
}

.login-email {
  margin-bottom: 15px;
}
.login-password {
  margin-bottom: 8px;
}

.login-check {
  padding-left: 10px;
}


.show-forget-container{
  flex-direction: row;
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

.custom-control-label {
  font-weight: 400;
}

.login-button-container{
  margin-top: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.login-bttn{
  border: 1px solid #3dbf75;
  border-radius: 8px;
  width: 140px;
  height: 35px;
  background-color:#3dbf75 ;
  text-align: center;
  
}