.slider {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  margin-top: 10vh;  
}

.slider-image {
  width: 654px;
  height: 392px;
  border-radius: 10px;
}

.right-arrow {
  position: absolute;
  top: 20%;
  right: 32px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  position: absolute;
  top: 20%;
  left: -32px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
  display: flex;
  
  
}

.slide.active{
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);

}



.slideText {
    opacity: 0;
    transition-duration: 1s ease;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    
  }
  
  .slideText.active{
      opacity: 1;
      transition-duration: 1s;
      transform: scale(1.08);
  
  }

.slider-text{
    width: 60%;
    text-align:left;
    font-size: 16px;
    font-weight: 400;
   
}

.slider-title{
    width: 60%;
    text-align:left;
    font-size: 32px;
    font-weight: 500;
   
}