.new-dropdown-menu {
  width: 160px;
  position: absolute;
  list-style: none;
  text-align: start;
  background-color: #fff;
  box-shadow: 0px 0px 0px 7px rgba(224, 224, 232, 0.1);
  min-height: 150px;
}

.new-dropdown-menu:hover {
  background-color: #fff;
}

.new-dropdown-menu li {
  cursor: pointer;
}

.new-dropdown-menu li:hover {
  background-color: #fff;
}

.new-dropdown-menu.clicked {
  display: none;
}

.new-dropdown-link {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: #fff;
  padding: 16px;
}

.dropdown-link {
  font-size: 12px;
  font-weight: 400;
  color: #000;
  text-decoration: none;
}

.dropdown-separator {
  width: 80%;
}

.dropdown-status-label {
  font-size: 12px;
  font-weight: 700;
}
.dropdown-status-text {
  font-size: 12px;
  font-weight: 400;
}
