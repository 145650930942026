:root {
  --primary: #ffdf9f;
}

.login-btn {
  padding: 4px 12px;
  border-radius: 4px;
  margin-left: 15px;
  outline: none;
  border: none;
  font-size: 14px;
  color: #000;
  cursor: pointer;
  background-color: var(--primary);
}

.top-header-login-btn {
  outline: none;
  border: none;
  font-size: 12px;
  color: fff;
  cursor: pointer;
}

.login-btn:hover {
  padding: 6px 18px;
  transition: all 0.3s ease-out;
  background-color: transparent;
  color: #000;
  border-radius: 4px;
  border: 2px solid var(--primary);
}

@media screen and (max-width: 576px) {
  .login-btn {
    text-align: center;
    width: 100%;
    margin-left: 0px;
  }
}

.top-header-login-btn {
  border: none;
  background: transparent;
  color: #fff;
}

.top-header-lock-button {
  padding-left: 6px;
}
