.contact-background{
    background: url(../../images/login_banner.png);
    background-repeat: no-repeat;
    width: 793px;
    height: 750px;
  }

  .banner-contact-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
  }

  .contact-title{
      font-weight: 700;
      font-size: 32px;
  }

  .contact-text{
      font-weight: 400;
      font-size: 16px;
  }
  