.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.logout-button {
  background-color: #167bff;
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.logout-button:hover {
  background-color: #0e5bbd;
}
