.footer {
  background-color: #000;
}

.footer-text {
  font-size: 14px;
  font-weight: 300;
}

.footer-text-title {
  font-size: 16px;
  font-weight: 400;
}

.footer-email {
  padding-left: 6px;
}

.footer-email-container {
  margin-bottom: 10px;
}

.content-container {
  margin-top: 6vh;
  margin-bottom: 6vh;
  color: #fff;
}

.footer-links-u {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  font-weight: 300;
}

.footer-links-u:hover {
  color: #85af4b;
}

.social-media-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 5px;
  color: #fff;
}

.footer-subscribe-email-input {
  border: 1px solid #fff;
  background-color: #3c3f38;
  text-align: center;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 300;
  width: 225px;
}

.footer-subscribe-button {
  margin-top: 10px;
  border: 1px solid #fff;
  background-color: #85af4b;
  text-align: center;
  width: 225px;
  padding: 10px 30px;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
}

.social-media-title {
  font-size: 14px;
  margin: auto;
}

.icon:hover {
  cursor: pointer;
}
