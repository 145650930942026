.user-registration-header-container,
.user-registration-contact-container,
.user-registration-role-container {
  margin-bottom: 30px;
}
.user-registration-input {
  width: 90%;
  padding: 12px 16px;
  font-size: 12px;
  font-weight: 400;
  border-radius: 50px;
  color: #000;
  background: #fff;
  border: 1px solid #34a0a4;
  transition: border 0.3s ease;
}

.user-registration-input:focus {
  outline: none;
  border-color: rgb(50, 168, 72);
}

.user-registration-form-group {
  position: relative;
  display: flex;
  width: 100%;
}


.user-registration-date-label {
  text-align: center;
  padding: 0px 10px;
  font-size: 14px;
  line-height: 20px;
  color: #1a759f;
  background: #76c893;
  border: 1px solid #34a0a4;
  transition: background 0.3s ease, border 0.3s ease, color 0.3s ease;
}

.user-registration-select {
  width: 90%;
  padding: 12px 16px;
  font-size: 12px;
  font-weight: 400;
  border-radius: 50px;
  color: #000;
  border: 1px solid #34a0a4;
  transition: border 0.3s ease;
}

.user-registration-btn {
  border: 2px solid #76c893;
  background: #44b88d;
  color: #000;
}

.user-registration-login-check {
  padding-left: 10px;
}

.custom-checkbox{
  margin-top: 6px;
  margin-left: 4px;
  
}


.user-registration-title{
  margin-top: 30px;
}