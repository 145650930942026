.notification-modal {
  position: absolute;
  top: 5%;
  width: 500px;
  max-height: 520px;
  background-color: #fff;
  border: 1px solid #3dbf75;
  border-radius: 15px;
}

.notification-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.notification-title {
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
}

.notification-modal-content {
  margin-top: 20px;
}
.notification-display-block {
  display: block;
}

.notification-display-none {
  display: none;
}

.notification-btn-container {
  background-color: aqua;
}

.notification-modal-user-text {
  margin-bottom: 0.2em;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
}
.notification-modal-message {
  font-size: 12px;
  font-weight: 400;
}
