.search-edit-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.search-edit-header-title {
  font-size: 28px;
  font-weight: 400;
  margin-top: 30px;
}

.search-edit-header-hidden {
  display: none;
}

.search-edit-header-select {
  width: 60%;
  padding: 8px 16px;
  line-height: 25px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  border: 1px solid #fff;
  color: #000;
  transition: border 0.3s ease;
}

.search-edit-header-input {
  padding: 8px 16px;
  line-height: 25px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  border: 1px solid #fff;
  color: #000;
}

.search-edit-content-container {
  background-color: #fff;
  min-height: 65vh;
}

.search-edit-content-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.search-edit-content-select {
  margin-top: 20px;
  padding: 8px 16px;
  line-height: 25px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  border: 1px solid #f3f3f7;
  color: #000;
}

.search-edit-content-result {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.search-edit-content-result-resume,
.search-edit-content-result-avatar,
.search-edit-content-result-click {
  margin-top: 10px;
  padding: 40px;
}

.search-edit-content-result-clients-text1,
.search-edit-content-result-clients-text2 {
  font-size: 14px;
  font-weight: 400;
}

.search-edit-content-result-space {
  margin-top: 60px;
}

.search-edit-content-result-b-ball {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #3b86ff;
  color: #3b86ff;
  padding-right: 10px;
  margin-right: 10px;
}

.search-edit-content-result-g-ball {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #26ae60;
  color: #26ae60;
  padding-right: 10px;
  margin-right: 10px;
}

.search-edit-content-result-r-ball {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #ff6565;
  color: #ff6565;
  padding-right: 10px;
  margin-right: 10px;
}

.search-edit-content-result-avatar {
  overflow-y: scroll;
  height: 500px;
}

.search-edit-content-result-box {
  border-bottom: 1px solid #f3f3f7;
  border-right: 1px solid #f3f3f7;
  border-top: 1px solid #f3f3f7;
  cursor: pointer;
}

.search-edit-content-result-bline {
  width: 3px;
  height: 80px;
  background-color: #3b86ff;
}

.search-edit-content-result-gline {
  width: 3px;
  height: 80px;
  background-color: #26ae60;
}

.search-edit-content-result-rline {
  width: 3px;
  height: 80px;
  background-color: #ff6565;
}

.search-edit-content-result-avatar-content {
  padding: 10px;
}

.search-edit-content-result-click-button {
  border: 1px solid #26ae60;
  background-color: #26ae60;
  border-radius: 10px;
  padding: 8px 20px;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
}

.search-edit-content-result-click-text {
  font-size: 14px;
  font-weight: 400;
  margin: 0.2em auto;
}

.search-edit-button {
  margin-top: 20px;
  background-color: rgb(50, 168, 72);
  width: 100px;
  border-radius: 20px;
  border-color: rgb(50, 168, 72);
}

.search-edit-button:hover {
  background-color: rgb(0, 128, 0);
}

.search-edit-button-container {
  margin-top: 20px;
}
