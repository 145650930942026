.new-navbar {
  background: #fff;
}

.new-navbar-logo {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  width: 80px;
  height: 80px;
}

.new-nav-menu {
  list-style: none;
  width: 100%;
}

.new-nav-links {
  color: #000;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.new-nav-links:hover {
  background-color: #ffdf9f;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

div.dropdown-menu.show {
  background: #0c6638;
}

.new-dropdown-menu {
  background: #0c6638;
  cursor: pointer;
}

.new-dropdown-menu:hover {
  background: #ffdf9f;
}

@media screen and (max-width: 576px) {
  .new-nav-menu {
    opacity: 1;
    transition: all 0.5s ease;
  }

  .new-nav-links {
    text-align: center;
    margin-top: 14px;
    padding: 1rem;
    width: 100%;
  }

  .dropdown {
    width: 100%;
    text-align: center;
  }
}

.top-header {
  background-color: #000;
  color: #fff;
  height: 35px;
}

.top-header-login-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-header-redes-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-header-icon:hover {
  cursor: pointer;
}
