.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.avatar-text-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.avatar-text1{
  margin-bottom: 0.3em;
}

.avatar-text1,.avatar-text2{
  font-size: 12px;
  font-weight: 400;
}

.header-avatar{
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
}

.header-avatar-btn{
  border: none;
  background-color: #fff;
}