.header-container,
.age-sex-container,
.adress-container,
.contact-container,
.num_process_container,
.contract-foto-container,
.plan-benefits-container,
.contract-foto-container,
.history-container,
.qr-container {
  margin-bottom: 30px;
}

.client-register-input {
  width: 90%;
  padding: 12px 16px;
  font-size: 12px;
  font-weight: 400;
  border-radius: 50px;
  color: #000;
  background: #fff;
  border: 1px solid #34a0a4;
  transition: border 0.3s ease;
}

.client-register-input:focus {
  outline: none;
  border-color: rgb(50, 168, 72);
}

.client-register-form-group {
  position: relative;
  display: flex;
  width: 100%;
}

.client-register-file-input-label {
  text-align: center;
  padding: 10px 0px;
  width: 20%;
  font-size: 12px;
  color: #1a759f;
  background: #76c893;
  border: 1px solid #34a0a4;
  border-radius: 50px;
  transition: background 0.3s ease, border 0.3s ease, color 0.3s ease;
}

.client-register-date-label {
  text-align: center;
  padding: 0px 10px;
  font-size: 14px;
  line-height: 20px;
  color: #1a759f;
  background: #76c893;
  border: 1px solid #34a0a4;
  transition: background 0.3s ease, border 0.3s ease, color 0.3s ease;
}

.client-register-select {
  width: 90%;
  padding: 12px 16px;
  font-size: 12px;
  font-weight: 400;
  border-radius: 50px;
  color: #000;
  border: 1px solid #34a0a4;
  transition: border 0.3s ease;
}

.client-register-btn {
  border: 2px solid #76c893;
  background: #44b88d;
  color: #000;
}

.register-client-help-block {
  color: red;
}

.qr-form {
  width: 100px;
  height: 100px;
}

.client-register-label {
  text-align: center;
  padding: 6px 10px;
  font-size: 12px;
  color: #1a759f;
  background: #76c893;
  border: 1px solid #34a0a4;
  transition: background 0.3s ease, border 0.3s ease, color 0.3s ease;
  border-radius: 50px;
}

.client-register-title {
  margin-top: 30px;
}

.client-parent {
  margin-top: 15px;
  margin-left: 4px;
}

.qr-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
