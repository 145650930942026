.dashboard-header-container {
  margin-left: 1px;
  min-height: 7vh;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e8;
}

.dashboard-header-content-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10%;
}

.dashboard-header-content {
  display: inline;
}

.dashboard-header-separator {
  height: 60%;
  margin-right: 8px;
  margin-left: 8px;
  border: 1px solid #e0e0e8;
}
